import {useParams} from "react-router-dom";
import {OrionEdi} from "../apps/orionedi/OrionEdi";
import {SuperFaktura} from "../apps/superfaktura/SuperFaktura";
import {Emorfic} from "../apps/emorfic/Emorfic";
import {Navision} from "../apps/navision/Navision";
import React, {useContext} from "react";
import {ClientContext} from "./ClientManager";
import {BusinessCentral} from "../apps/businesscentral/BusinessCentral";
import {Lokia} from "../apps/lokia/Lokia";
import {Pohoda2} from "../apps/pohoda2/Pohoda2";
import {DefaultApp} from "../apps/defaultapp/DefaultApp";
import {Vario} from "../apps/vario/Vario";
import {KalasClientService} from "../client-apps/kalas/KalasClientService";

export const ClientService = () => {
    const client = useContext(ClientContext);
    const params = useParams<{ service: string  }>();
    const service = client?.services.find(x => x.name.toLowerCase() === params.service);

    if (!service) {
      return null;
    }

    const clientApplication = (function () {
      switch (client?.code) {
        case 'kalas':
          return <KalasClientService service={service} />;
        default:
          return null;
      }
    })();

    if (clientApplication) {
      return clientApplication;
    }

    switch (service.application) {
        case 'BusinessCentral':
            return <BusinessCentral service={service} />;
        case 'OrionEdi':
            return <OrionEdi />;
        case 'SuperFaktura':
            return <SuperFaktura />;
        case 'Emorfic':
            return <Emorfic service={service} />;
        case 'Lokia':
            return <Lokia service={service} />;
        case 'Pohoda2':
            return <Pohoda2 service={service} />;
        case 'Vario':
            return <Vario service={service} />;
        default:
            return <DefaultApp service={service} />;
    }
};
