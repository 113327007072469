import {Box, Tab, Tabs} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import React, {useState} from "react";
import {Outbox} from "../outbox/Outbox";
import {ServiceDto} from "../../../api-client/model";
import {useParams} from "react-router-dom";
import {Inbox} from "../inbox/Inbox";
import {ApplicationTitle} from "./ApplicationTitle";

interface ExtraTab {
  label: string,
  value: string,
  content: () => React.ReactNode,
}

export type ApplicationProps = {
  service: ServiceDto;
  extraTabs?: ExtraTab[];
};

export const Application: React.FC<ApplicationProps> = ({service, extraTabs}) => {
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId as string;
  const [activeTab, setActiveTab] = useState("inbox");

  return (
    <>
      <ApplicationTitle service={service}/>

      <TabContext value={activeTab}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs
            value={activeTab}
            onChange={(_, value) => setActiveTab(value)}
          >
            <Tab label="Inbox" value="inbox"></Tab>
            <Tab label="Outbox" value="outbox"></Tab>
            {extraTabs?.map((tab) => (
              <Tab label={tab.label} value={tab.value}></Tab>
            ))}
          </Tabs>
        </Box>

        <TabPanel value="inbox" sx={{px: 0}}>
          <Inbox clientId={clientId} application={service.code}/>
        </TabPanel>

        <TabPanel value="outbox" sx={{px: 0}}>
          <Outbox clientId={clientId} application={service.code}/>
        </TabPanel>

        {extraTabs?.map((tab) => (
          <TabPanel value={tab.value} sx={{px: 0}}>
            {tab.content()}
          </TabPanel>
        ))}
      </TabContext>
    </>
  );
};
