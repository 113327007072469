import {
  Alert,
  Box,
  Button, Chip, CircularProgress, Divider,
  IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip, Typography,
} from "@mui/material";
import React from "react";
import {useConfigureJob, useDeleteJob, useGetSchedulerJobs} from "../api-client/scheduler";
import {
  Add, AlarmOff, Check,
  CheckCircle,
  DeleteOutline, Description, DescriptionOutlined, Error, HelpOutline,
  Settings, Toc
} from "@mui/icons-material";
import TimeAgo from "react-timeago";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {CreateSchedulerJob} from "./CreateSchedulerJob";
import {ConfirmDialog} from "../ui/ConfirmDialog";
import {SchedulerJobDto} from "../api-client/model";
import {UpdateSchedulerJob} from "./UpdateSchedulerJob";
import {GridTripleDotsVerticalIcon} from "@mui/x-data-grid";
import {cronDescription} from "../utils/cron";
import {ClientCtx} from "./ClientDetail";
import {useToggleScheduler} from "../api-client/clients";


export const ClientScheduler = () => {
  const params = useParams();
  const ctx = useOutletContext<ClientCtx>();
  const client = params.clientId as string;
  const [createJobOpen, setCreateJobOpen] = React.useState(false);
  const [jobToUpdate, setJobToUpdate] = React.useState<SchedulerJobDto | null>(null);
  const [jobToDelete, setJobToDelete] = React.useState<SchedulerJobDto | null>(null);
  const [confirmSchedulerToggle, setConfirmSchedulerToggle] = React.useState(false);

  // Queries
  const jobsQuery = useGetSchedulerJobs({ client }, {
    query: {
      refetchInterval: 15000
    }
  });
  const jobs = jobsQuery.data || [];

  // Mutations
  const configureMutation = useConfigureJob();
  const deleteMutation = useDeleteJob();
  const toggleSchedulerMutation = useToggleScheduler();

  async function configure(id: number, audit: boolean, enabled: boolean) {
    await configureMutation.mutateAsync({
      id: id.toString(),
      data: {
        enabled,
        audit,
      },
    });
    await jobsQuery.refetch();
  }

  async function deleteJob(job: SchedulerJobDto) {
    setJobToDelete(null);
    await deleteMutation.mutateAsync({ id: job.id });
    await jobsQuery.refetch();
  }

  async function toggleScheduler() {
    setConfirmSchedulerToggle(false);
    await toggleSchedulerMutation.mutateAsync({
      id: client,
      data: {
        enabled: !ctx?.client?.schedulerEnabled
      }
    })
    await ctx?.onSchedulerToggle();
  }

  if (jobsQuery.isInitialLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 1,
        px: 3
      }}>
        <Typography variant="h5">Scheduler</Typography>

        <Box sx={{ display: "flex", alignItems: 'center' }}>
          <Box sx={{ mr: 4 }}>
            <Switch
              checked={ctx?.client?.schedulerEnabled}
              onChange={() => setConfirmSchedulerToggle(true)}
              disabled={toggleSchedulerMutation.isLoading
            } />
            <Typography variant="caption">Enabled</Typography>
          </Box>

          <Button
            variant="contained"
            size="small"
            onClick={() => setCreateJobOpen(true)}
            startIcon={<Add />}
          >Create job</Button>
        </Box>
      </Box>

      {jobs.length === 0 ? (
        <Box sx={{ p: 3, pt: 0 }}>
          <Alert severity="info">No scheduler job found</Alert>
        </Box>
      ) : (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Cron</TableCell>
                <TableCell>Args</TableCell>
                <TableCell align="center">Health</TableCell>
                <TableCell>Last run</TableCell>
                <TableCell align="right">Max Rand Delay</TableCell>
                <TableCell align="right">Average runtime</TableCell>
                <TableCell align="center">Exclusive</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="right" sx={{ p: 0 }}>Enabled</TableCell>
                <TableCell sx={{ width: 20 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs.map((job) => (
                <JobRow
                  key={job.id}
                  job={job}
                  onEdit={setJobToUpdate}
                  onDelete={setJobToDelete}
                  disabled={jobsQuery.isLoading || configureMutation.isLoading}
                  onConfigure={(id, enabled) => configure(id, job.audit, enabled)}
                  schedulerEnabled={ctx?.client?.schedulerEnabled}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <CreateSchedulerJob
        clientId={client}
        open={createJobOpen}
        onClose={() => setCreateJobOpen(false)}
        onCreated={() => jobsQuery.refetch()}
      />

      <UpdateSchedulerJob
        clientId={client}
        job={jobToUpdate!}
        open={jobToUpdate != null}
        onClose={() => setJobToUpdate(null)}
        onUpdated={() => jobsQuery.refetch()}
      />

      <ConfirmDialog
        open={jobToDelete != null}
        onCancel={() => setJobToDelete(null)}
        onConfirm={() => deleteJob(jobToDelete!)}
        title="Delete job"
        danger
        confirmLabel="Delete"
      >
        {jobToDelete?.name}
      </ConfirmDialog>

      <ConfirmDialog
        open={confirmSchedulerToggle}
        onCancel={() => setConfirmSchedulerToggle(false)}
        onConfirm={toggleScheduler}
        title="Confirm scheduler toggle"
        confirmLabel={ctx?.client?.schedulerEnabled ? 'Disable' : 'Enable'}
        danger={ctx?.client?.schedulerEnabled}
      >
        Scheduler will be{' '}
        {ctx?.client?.schedulerEnabled ? 'disabled' : 'enabled'}
      </ConfirmDialog>
    </>
  );
};

const JobRow: React.FC<{
  job: SchedulerJobDto;
  onEdit: (job: SchedulerJobDto) => void;
  onDelete: (job: SchedulerJobDto) => void;
  onConfigure: (id: number, enable: boolean) => void;
  disabled?: boolean;
  schedulerEnabled?: boolean;
}> = ({ job, onEdit, onDelete, disabled, onConfigure, schedulerEnabled }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function edit() {
    onEdit(job);
    setAnchorEl(null);
  }

  function remove() {
    onDelete(job);
    setAnchorEl(null);
  }

  function showRuns() {
    navigate(`/jobs?schedulerJobId=${job.id}`);
  }

  function toTimeString(duration: number) {
    return new Date(duration * 1000).toISOString().slice(11, 19);
  }

  return (
    <TableRow key={job.id} hover sx={{ opacity: schedulerEnabled ? 1 : 0.5 }}>
      <TableCell>
        <Typography fontSize="small" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Link onClick={edit} sx={{ cursor: 'pointer' }}>{job.name}</Link>

          {job.description && (
            <Tooltip title={job.description}>
              <DescriptionOutlined fontSize="small" sx={{ opacity: 0.5 }} />
            </Tooltip>
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Tooltip title={cronDescription(job.cron)}>
          <Chip label={job.cron} size="small" />
        </Tooltip>
      </TableCell>
      <TableCell>
        {job.args?.split('--').filter(Boolean).map((arg, i) => (
          <Chip key={i} label={`--${arg}`} size="small" sx={{ mr: 1, maxWidth: 400 }} />
        ))}
      </TableCell>
      <TableCell align="center">
        {job.stats ? (
          <Typography fontSize="small" sx={{ display: 'flex', justifyContent: 'center' }}>
            {job.stats.failRatio === 0 && (<CheckCircle color="success" fontSize="small" />)}
            {job.stats.failRatio > 0 && job.stats.failRatio < 0.5 && (<Error color="warning" fontSize="small" />)}
            {job.stats.failRatio > 0.5 && (<Error color="error" fontSize="small" />)}
          </Typography>
        ) : <Typography fontSize="small" sx={{ color: 'text.secondary' }}>-</Typography>}
      </TableCell>
      <TableCell>
        <Typography fontSize="small">{job.lastRun ? <TimeAgo date={job.lastRun} /> : '-'}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography fontSize="small">{job.maxDelay ? `${job.maxDelay} ms` : ""}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography fontSize="small">{job.stats ? toTimeString(job.stats.avgDuration) : "-"}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography fontSize="small" sx={{ display: 'flex', justifyContent: 'center' }}>{job.exclusiveRun ? <Check color="success" fontSize="small" /> : ''}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography fontSize="small" sx={{ display: 'flex', justifyContent: 'center' }}>
          {job.enabled ? (
            <>
              {job.status === 'error' && <Tooltip title={job.error}><Error color="error" fontSize="small" /></Tooltip>}
              {job.status === 'ready' && <Tooltip title="Scheduled"><CheckCircle color="success" fontSize="small" /></Tooltip>}
            </>
          ) : (
            <Tooltip title="Disabled">
              <AlarmOff fontSize="small" sx={{ opacity: 0.5 }} />
            </Tooltip>
          )}
        </Typography>
      </TableCell>
      <TableCell align="right" sx={{ p: 0 }}>
        <Switch
          color="success"
          checked={job.enabled}
          disabled={disabled}
          onChange={(_, checked) =>
            onConfigure(job.id, checked)
          }
          size="small"
        />
      </TableCell>
      <TableCell align="right" sx={{ py: 0 }}>
        <IconButton size="small" onClick={e => setAnchorEl(e.target as HTMLBaseElement)}><GridTripleDotsVerticalIcon fontSize="small" /></IconButton>

        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          sx={{ py: 0 }}
          PaperProps={{ sx: { py: 0 } }}
        >
          <MenuItem onClick={edit}>
            <ListItemIcon><Settings fontSize="small" /></ListItemIcon>
            <ListItemText>Configure</ListItemText>
          </MenuItem>
          <MenuItem onClick={showRuns}>
            <ListItemIcon><Toc fontSize="small" /></ListItemIcon>
            <ListItemText>Job runs</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={remove}>
            <ListItemIcon sx={{ color: 'error.main' }}><DeleteOutline fontSize="small" /></ListItemIcon>
            <ListItemText sx={{ color: 'error.main' }}>Delete</ListItemText>
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}
