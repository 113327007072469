import {useParams} from "react-router-dom";
import {ServiceDto} from "../../api-client/model";
import React, {useState} from "react";
import {ApplicationTitle} from "../common/application/ApplicationTitle";
import {TabContext, TabPanel} from "@mui/lab";
import {Box, Tab, Tabs} from "@mui/material";
import {Outbox} from "../common/outbox/Outbox";
import {TypeMessageQueue} from "../common/queue/TypeMessageQueue";
import {Inbox} from "../common/inbox/Inbox";
import {VarioOrderQueue} from "./VarioOrderQueue";

type Props = {
  service: ServiceDto;
};

export function Vario({ service }: Props) {
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId as string;
  const [activeTab, setActiveTab] = useState("orders");

  return (
    <>
      <ApplicationTitle service={service} />

      <TabContext value={activeTab}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs
            value={activeTab}
            onChange={(_, value) => setActiveTab(value)}
          >
            <Tab label="Orders" value="orders"></Tab>
            <Tab label="Inbox" value="inbox"></Tab>
            <Tab label="Outbox" value="outbox"></Tab>
          </Tabs>
        </Box>

        <TabPanel value="queues" sx={{px: 0}}>
          <TypeMessageQueue clientId={clientId} application={service.code} />
        </TabPanel>

        <TabPanel value="orders" sx={{px: 0}}>
          <VarioOrderQueue clientId={clientId} application={service.code} />
        </TabPanel>

        <TabPanel value="inbox" sx={{px: 0}}>
          <Inbox clientId={clientId} application={service.code} />
        </TabPanel>

        <TabPanel value="outbox" sx={{px: 0}}>
          <Outbox clientId={clientId} application={service.code} />
        </TabPanel>
      </TabContext>
    </>
  );
}
