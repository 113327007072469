import {useParams} from "react-router-dom";
import React from "react";
import {TypeMessageQueue} from "../common/queue/TypeMessageQueue";
import {EmorficCustomerQueue} from "./customer-queue/EmorficCustomerQueue";
import {EmorficOrderQueue} from "./order-queue/EmorficOrderQueue";
import {Application, ApplicationProps} from "../common/application/Application";

export function Emorfic({service, extraTabs}: ApplicationProps) {
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId as string;

  return (
    <>
      <Application
        service={service}
        extraTabs={[
          {
            label: "Queues",
            value: "queues",
            content: () => <TypeMessageQueue clientId={clientId} application={service.code}/>,
          },
          {
            label: "Orders",
            value: "orders",
            content: () => <EmorficOrderQueue clientId={clientId} application={service.code}/>,
          },
          {
            label: "Customers",
            value: "customers",
            content: () => <EmorficCustomerQueue clientId={clientId} application={service.code}/>,
          },
          ...extraTabs ?? [],
        ]}
      />
    </>
  );
}
