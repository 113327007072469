/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Dirigos API
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  BusinessCentralOrderQueueMessageDto,
  BusinessCentralOrderQueueDto,
  GetBusinessCentralOrderQueueMessagesParams,
  BusinessCentralProductQueueMessageDto,
  BusinessCentralProductQueueDto,
  GetBusinessCentralProductQueueMessagesParams,
} from "./model";
import { http } from "../http";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const businessCentralOrderQueueRetryMessage = (
  client: string,
  businessCentralApplication: string,
  messageId: string,
  options?: SecondParameter<typeof http>
) => {
  return http<BusinessCentralOrderQueueMessageDto>(
    {
      url: `/api/v1/clients/${client}/${businessCentralApplication}/order-queue/${messageId}/retry`,
      method: "post",
    },
    options
  );
};

export type BusinessCentralOrderQueueRetryMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof businessCentralOrderQueueRetryMessage>>
>;

export type BusinessCentralOrderQueueRetryMessageMutationError = unknown;

export const useBusinessCentralOrderQueueRetryMessage = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof businessCentralOrderQueueRetryMessage>>,
    TError,
    { client: string; businessCentralApplication: string; messageId: string },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof businessCentralOrderQueueRetryMessage>>,
    { client: string; businessCentralApplication: string; messageId: string }
  > = (props) => {
    const { client, businessCentralApplication, messageId } = props ?? {};

    return businessCentralOrderQueueRetryMessage(
      client,
      businessCentralApplication,
      messageId,
      requestOptions
    );
  };

  return useMutation<
    Awaited<ReturnType<typeof businessCentralOrderQueueRetryMessage>>,
    TError,
    { client: string; businessCentralApplication: string; messageId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getBusinessCentralOrderQueueMessages = (
  client: string,
  businessCentralApplication: string,
  params?: GetBusinessCentralOrderQueueMessagesParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<BusinessCentralOrderQueueDto>(
    {
      url: `/api/v1/clients/${client}/${businessCentralApplication}/order-queue`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetBusinessCentralOrderQueueMessagesQueryKey = (
  client: string,
  businessCentralApplication: string,
  params?: GetBusinessCentralOrderQueueMessagesParams
) => [
  `/api/v1/clients/${client}/${businessCentralApplication}/order-queue`,
  ...(params ? [params] : []),
];

export type GetBusinessCentralOrderQueueMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBusinessCentralOrderQueueMessages>>
>;
export type GetBusinessCentralOrderQueueMessagesQueryError = unknown;

export const useGetBusinessCentralOrderQueueMessages = <
  TData = Awaited<ReturnType<typeof getBusinessCentralOrderQueueMessages>>,
  TError = unknown
>(
  client: string,
  businessCentralApplication: string,
  params?: GetBusinessCentralOrderQueueMessagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBusinessCentralOrderQueueMessages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetBusinessCentralOrderQueueMessagesQueryKey(
      client,
      businessCentralApplication,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBusinessCentralOrderQueueMessages>>
  > = ({ signal }) =>
    getBusinessCentralOrderQueueMessages(
      client,
      businessCentralApplication,
      params,
      requestOptions,
      signal
    );

  const query = useQuery<
    Awaited<ReturnType<typeof getBusinessCentralOrderQueueMessages>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(client && businessCentralApplication),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const businessCentralProductQueueRetryMessage = (
  client: string,
  businessCentralApplication: string,
  messageId: string,
  options?: SecondParameter<typeof http>
) => {
  return http<BusinessCentralProductQueueMessageDto>(
    {
      url: `/api/v1/clients/${client}/${businessCentralApplication}/product-queue/${messageId}/retry`,
      method: "post",
    },
    options
  );
};

export type BusinessCentralProductQueueRetryMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof businessCentralProductQueueRetryMessage>>
>;

export type BusinessCentralProductQueueRetryMessageMutationError = unknown;

export const useBusinessCentralProductQueueRetryMessage = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof businessCentralProductQueueRetryMessage>>,
    TError,
    { client: string; businessCentralApplication: string; messageId: string },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof businessCentralProductQueueRetryMessage>>,
    { client: string; businessCentralApplication: string; messageId: string }
  > = (props) => {
    const { client, businessCentralApplication, messageId } = props ?? {};

    return businessCentralProductQueueRetryMessage(
      client,
      businessCentralApplication,
      messageId,
      requestOptions
    );
  };

  return useMutation<
    Awaited<ReturnType<typeof businessCentralProductQueueRetryMessage>>,
    TError,
    { client: string; businessCentralApplication: string; messageId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getBusinessCentralProductQueueMessages = (
  client: string,
  businessCentralApplication: string,
  params?: GetBusinessCentralProductQueueMessagesParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<BusinessCentralProductQueueDto>(
    {
      url: `/api/v1/clients/${client}/${businessCentralApplication}/product-queue`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetBusinessCentralProductQueueMessagesQueryKey = (
  client: string,
  businessCentralApplication: string,
  params?: GetBusinessCentralProductQueueMessagesParams
) => [
  `/api/v1/clients/${client}/${businessCentralApplication}/product-queue`,
  ...(params ? [params] : []),
];

export type GetBusinessCentralProductQueueMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBusinessCentralProductQueueMessages>>
>;
export type GetBusinessCentralProductQueueMessagesQueryError = unknown;

export const useGetBusinessCentralProductQueueMessages = <
  TData = Awaited<ReturnType<typeof getBusinessCentralProductQueueMessages>>,
  TError = unknown
>(
  client: string,
  businessCentralApplication: string,
  params?: GetBusinessCentralProductQueueMessagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBusinessCentralProductQueueMessages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetBusinessCentralProductQueueMessagesQueryKey(
      client,
      businessCentralApplication,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBusinessCentralProductQueueMessages>>
  > = ({ signal }) =>
    getBusinessCentralProductQueueMessages(
      client,
      businessCentralApplication,
      params,
      requestOptions,
      signal
    );

  const query = useQuery<
    Awaited<ReturnType<typeof getBusinessCentralProductQueueMessages>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(client && businessCentralApplication),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
