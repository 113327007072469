/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Dirigos API
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  VarioOrderQueueMessageDto,
  VarioOrderQueueDto,
  GetVarioOrderQueueMessagesParams,
} from "./model";
import { http } from "../http";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const varioRetryMessage = (
  client: string,
  messageId: string,
  options?: SecondParameter<typeof http>
) => {
  return http<VarioOrderQueueMessageDto>(
    {
      url: `/api/v1/clients/${client}/vario/order-queue/${messageId}/retry`,
      method: "post",
    },
    options
  );
};

export type VarioRetryMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof varioRetryMessage>>
>;

export type VarioRetryMessageMutationError = unknown;

export const useVarioRetryMessage = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof varioRetryMessage>>,
    TError,
    { client: string; messageId: string },
    TContext
  >;
  request?: SecondParameter<typeof http>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof varioRetryMessage>>,
    { client: string; messageId: string }
  > = (props) => {
    const { client, messageId } = props ?? {};

    return varioRetryMessage(client, messageId, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof varioRetryMessage>>,
    TError,
    { client: string; messageId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getVarioOrderQueueMessages = (
  client: string,
  params?: GetVarioOrderQueueMessagesParams,
  options?: SecondParameter<typeof http>,
  signal?: AbortSignal
) => {
  return http<VarioOrderQueueDto>(
    {
      url: `/api/v1/clients/${client}/vario/order-queue`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetVarioOrderQueueMessagesQueryKey = (
  client: string,
  params?: GetVarioOrderQueueMessagesParams
) => [
  `/api/v1/clients/${client}/vario/order-queue`,
  ...(params ? [params] : []),
];

export type GetVarioOrderQueueMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getVarioOrderQueueMessages>>
>;
export type GetVarioOrderQueueMessagesQueryError = unknown;

export const useGetVarioOrderQueueMessages = <
  TData = Awaited<ReturnType<typeof getVarioOrderQueueMessages>>,
  TError = unknown
>(
  client: string,
  params?: GetVarioOrderQueueMessagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getVarioOrderQueueMessages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof http>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetVarioOrderQueueMessagesQueryKey(client, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getVarioOrderQueueMessages>>
  > = ({ signal }) =>
    getVarioOrderQueueMessages(client, params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getVarioOrderQueueMessages>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!client,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
