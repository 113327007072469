import React from "react";
import {ServiceDto} from "../../api-client/model";
import {KalasEmorfic} from "./emorfic/KalasEmorfic";

export const KalasClientService: React.FC<{ service: ServiceDto }> = ({ service }) => {

  switch (service.application) {
    case 'Emorfic':
      return <KalasEmorfic service={service} />
    default:
      return null;
  }

};
