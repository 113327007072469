import {useParams} from "react-router-dom";
import React from "react";
import {Emorfic} from "../../../apps/emorfic/Emorfic";
import {KalasEmorficMyShopQueue} from "./my-shop-queue/KalasEmorficMyShopQueue";
import {ApplicationProps} from "../../../apps/common/application/Application";

export function KalasEmorfic({service}: ApplicationProps) {
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId as string;

  return (
    <>
      <Emorfic
        service={service}
        extraTabs={[
          ...(service.code === "my_shop"
            ? [{
              label: "MyShops",
              value: "myshops",
              content: () => <KalasEmorficMyShopQueue clientId={clientId} application={service.code} />,
            }]
            : []),
        ]}
      />
    </>
  );
}
