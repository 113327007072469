import {Outbox} from "../common/outbox/Outbox";
import {useParams} from "react-router-dom";
import React, {useState} from "react";
import {Box, Tab, Tabs} from "@mui/material";
import {TabContext, TabPanel} from "@mui/lab";
import {ServiceDto} from "../../api-client/model";
import {ApplicationTitle} from "../common/application/ApplicationTitle";
import {Pohoda2OrderQueue} from "./order-queue/Pohoda2OrderQueue";
import {Pohoda2TransactionQueue} from "./transaction-queue/Pohoda2TransactionQueue";
import {Pohoda2CustomerQueue} from "./customer-queue/Pohoda2CustomerQueue";
import {Pohoda2ProductQueue} from "./product-queue/Pohoda2ProductQueue";
import {Pohoda2InvoiceQueue} from "./invoice-queue/Pohoda2InvoiceQueue";
import {Inbox} from "../common/inbox/Inbox";

type Props = {
  service: ServiceDto;
};

export function Pohoda2({service}: Props) {
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId as string;
  const [activeTab, setActiveTab] = useState("orders");

  return (
    <>
      <ApplicationTitle service={service} />

      <TabContext value={activeTab}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs
            value={activeTab}
            onChange={(_, value) => setActiveTab(value)}
          >
            <Tab label="Orders" value="orders"></Tab>
            <Tab label="Invoices" value="invoices"></Tab>
            <Tab label="Transactions" value="transactions"></Tab>
            <Tab label="Customers" value="customers"></Tab>
            <Tab label="Products" value="products"></Tab>
            <Tab label="Inbox" value="inbox"></Tab>
            <Tab label="Outbox" value="outbox"></Tab>
          </Tabs>
        </Box>

        <TabPanel value="orders" sx={{px: 0}}>
          <Pohoda2OrderQueue clientId={clientId} application={service.code} />
        </TabPanel>

        <TabPanel value="invoices" sx={{px: 0}}>
          <Pohoda2InvoiceQueue clientId={clientId} application={service.code} />
        </TabPanel>

        <TabPanel value="transactions" sx={{px: 0}}>
          <Pohoda2TransactionQueue clientId={clientId} application={service.code} />
        </TabPanel>

        <TabPanel value="customers" sx={{px: 0}}>
          <Pohoda2CustomerQueue clientId={clientId} application={service.code} />
        </TabPanel>

        <TabPanel value="products" sx={{px: 0}}>
          <Pohoda2ProductQueue clientId={clientId} application={service.code} />
        </TabPanel>

        <TabPanel value="inbox" sx={{px: 0}}>
          <Inbox clientId={clientId} application={service.code} />
        </TabPanel>

        <TabPanel value="outbox" sx={{px: 0}}>
          <Outbox clientId={clientId} application={service.code} />
        </TabPanel>
      </TabContext>
    </>
  );
}
